.hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
  border-color: rgb(203 213 225 / var(--tw-bg-opacity));
  position: relative;
  display: inline-block;
}
.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}


.background-color-detail {
  background: rgba(0, 0, 0, 0.2);
}

