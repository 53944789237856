.arrow-size{
    width: 50px;
    height: 50px;
}

.width-100 {
    width: 100%;
}

.pagination-wrapper {
    display:  flex;
    justify-content: center;
    align-items: center;
}

.pagination-wrapper > svg {
    cursor: pointer;
}

.disabled-opacity {
    opacity: 10%;
}