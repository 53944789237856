
.grid-container {
  width: 100%;
  height: calc(100vh - 200px);
}

.grid-container svg g {
  fill: #000;
  fill-opacity: 1;
}
.grid-container svg g:hover {
  fill: #e2dfdf;
  fill-opacity: 0.7;
}
.grid-container svg g:hover text {
  fill-opacity: 1;
}

.grid-container svg g polygon {
  /* stroke: #000000;
  stroke-width: 0.2;
  transition: fill-opacity .5s; */
}
.grid-container svg g text {
  font-size: 0.22em;
  fill: white;
  fill-opacity: 0.7;
  transition: fill-opacity .5s;
}
.grid-container svg path {
  fill: none;
  stroke: #000000;
  stroke-width: 0.2em;
  stroke-opacity: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}


.pagenumber-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap:10px;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;

}

.column-flex {
  flex-direction: column;
}

.gap-1 {
  gap: 10px;
}



@keyframes nextPage {
  0% {
    opacity: 0;
    transform: translate(10em, 0);
  }


  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes prevPage {
  0% {
    opacity: 0;
    transform: translate(-10em, 0);
  }


  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}


  .react-transform-wrapper{
    width: 100% !important;
    border-radius: 50px;
  }

  .react-transform-component {
    width: 100% !important;
  }